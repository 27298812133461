import { Link } from 'react-router-dom';
import { Navigation } from '../navigation';
function Header() {
  return (
    <header>
      <div className=" flex fixed top-0 justify-between w-screen h-10 bg-green-700">
        <h1 className="">
          <Link to="/">
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/img/crown-gold2.png`}
              alt="rerate-home"
              width={80}
              height={20}
            ></img>
          </Link>
        </h1>
        <Navigation />
      </div>
    </header>
  );
}
export default Header;
