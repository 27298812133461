import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Contact from 'src/component/contact';
import Flutter from 'src/component/flutter';
import Home from 'src/component/home';
import News from 'src/component/news';
import Service from 'src/component/service';
import 'src/index.css';
import { MainLayout } from 'src/layout';
import reportWebVitals from 'src/reportWebVitals';
import Authentication_completed from './component/authentication-completed';
import Authentication_remove from './component/authentication-remove';
import About from './component/service/about';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout page="home">
              <Home />
            </MainLayout>
          }
        />
        <Route
          path="/service"
          element={
            <MainLayout page="service">
              <Service />
            </MainLayout>
          }
        >
          <Route
            path="/service/about"
            element={
              <MainLayout page="about">
                <About />
              </MainLayout>
            }
          />
        </Route>

        <Route
          path="/news"
          element={
            <MainLayout page="news">
              <News />
            </MainLayout>
          }
        />
        <Route />
        <Route
          path="/contact"
          element={
            <MainLayout page="contact">
              <Contact />
            </MainLayout>
          }
        />
        <Route
          path="/web/app.html"
          element={
            <MainLayout page="flutter">
              <Flutter />
            </MainLayout>
          }
        />
        
        <Route
          path="/authentication-completed"
          element={
            <MainLayout page="contact">
              <Authentication_completed />
            </MainLayout>
          }
        />
        <Route
          path="/authentication-remove"
          element={
            <MainLayout page="contact">
              <Authentication_remove />
            </MainLayout>
          }
        /> 
        
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
