import { IoHomeOutline } from '@react-icons/all-files/io5/IoHomeOutline';
// import React from 'react';
const Contact = () => {
  return (
    // <IconContext.Provider value={{ color: '#ccc' }}>
    <div className=" h-20">
      <IoHomeOutline className="top-5 w-5 h-5 text-5xl text-blue-500" />
    </div>
    // </IconContext.Provider>
  );
};
export default Contact;
