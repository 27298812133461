import { IoHomeOutline } from '@react-icons/all-files/io5/IoHomeOutline';
import { IoMenuOutline } from '@react-icons/all-files/io5/IoMenuOutline';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Items = [
  {
    href: '/',
    label: 'home',
    icon: <IoHomeOutline className="top-5 w-20 h-20 text-5xl text-blue-500" />,
  },
  {
    href: '/web/app.html',
    label: 'service',
    icon: <IoHomeOutline className="top-5 w-20 h-20 text-5xl text-blue-500" />,
  },
  {
    href: 'https://docs.google.com/forms/d/1dmia7VSoxVICQkO9fvhRDcmJ_Y4Fc2aE9WVrDx-ixBI/prefill',
    label: 'contact',
    icon: <IoHomeOutline className="top-5 w-20 h-20 text-5xl text-blue-500" />,
  },
];
export const Navigation = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menu = () => {
    setOpenMenu(!openMenu);
  };
  const items = Items.map(({ href, label, icon }) => {
    return (
      <li key={label} className="hidden md:flex">
        {label === 'contact' ? (
          <a className="flex" target="_blank" rel="noopener noreferrer" href={href}>
            {label}
            {icon}
          </a>
        ) : (
          <Link className="flex" to={href}>
            {label}
            {icon}
          </Link>
        )}
      </li>
    );
  });
  return (
    <nav>
      <ul className="flex">
        {items}
        <li className="lg:hidden">
          <button aria-label="menu" onClick={menu}>
            <IoMenuOutline className="text-5xl" />
          </button>
        </li>
        {openMenu ? <ul className="flex right-0 flex-col">{items}</ul> : undefined}
      </ul>
    </nav>
  );
};
