import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Footer } from 'src/component/footer';
import Header from 'src/component/header';
import { LayoutProps } from './types';

export function MainLayout({ page, children }: LayoutProps): JSX.Element {
  return (
    <>
      <HelmetProvider>
        <Helmet title={`Rerate ${page}`} meta={[{ property: 'og:description', content: 'uuu' }]} />
      </HelmetProvider>
      {window.location.pathname === '/contact'||window.location.pathname ==='/authentication-remove' ? null : <Header />}
      <main>{children}</main>
      {window.location.pathname === '/contact' ? null : <Footer />}
    </>
  );
}
