import { IoCheckmarkCircle } from '@react-icons/all-files/io5/IoCheckmarkCircle';
const Authentication_remove = () => {
  return (
    <div className="flex-col justify-center items-center w-screen text-center">
      <p>削除が完了しました</p>
      <div className="">
        <IoCheckmarkCircle className="w-screen text-6xl  text-center" />
      </div>
    </div>
  );
};
export default Authentication_remove;
